import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  UncontrolledDropdown,
} from "reactstrap";
import { Settings } from "react-feather";
import {
  debugBecomeCharityAdmin,
  debugBecomeContentCreator,
  debugBecomeContentManager,
  debugBecomeSystemAdmin,
  signOut,
} from "../redux/actions/userActions";

import React from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";

const NavbarComponent = ({
  dispatch,
  toggleSidebar,
  signOut,
  debugBecomeCharityAdmin,
  debugBecomeSystemAdmin,
  debugBecomeContentCreator,
  debugBecomeContentManager,
  userName,
}) => {
  return (
    <Navbar color="white" light expand>
      <span
        className="sidebar-toggle d-flex mr-2"
        onClick={() => {
          toggleSidebar();
        }}
      >
        <i className="hamburger align-self-center" />
      </span>

      <Collapse navbar>
        <Nav className="ml-auto" navbar>
          <UncontrolledDropdown nav inNavbar>
            <span className="d-inline-block d-sm-none">
              <DropdownToggle nav caret>
                <Settings size={18} className="align-middle" />
              </DropdownToggle>
            </span>
            <span className="d-none d-sm-inline-block">
              <DropdownToggle nav caret>
                <span className="text-dark">{userName}</span>
              </DropdownToggle>
            </span>
            <DropdownMenu right>
              {/* <DropdownItem>
                <User size={18} className="align-middle mr-2" />
                Profile
              </DropdownItem>
              <DropdownItem>
                <PieChart size={18} className="align-middle mr-2" />
                Analytics
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Settings & Privacy</DropdownItem>
              <DropdownItem>Help</DropdownItem> */}
              {process.env.NODE_ENV === "development" ? (
                <>
                  <DropdownItem onClick={() => debugBecomeCharityAdmin()}>
                    Change to Charity Admin Role
                  </DropdownItem>
                  <DropdownItem onClick={() => debugBecomeContentManager()}>
                    Change to Content Manager Role
                  </DropdownItem>
                  <DropdownItem onClick={() => debugBecomeContentCreator()}>
                    Change to Content Creator Role
                  </DropdownItem>
                </>
              ) : null}
              <DropdownItem href="/auth/change-password">
                Change Password
              </DropdownItem>
              <DropdownItem onClick={signOut}>Sign out</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default connect(
  (store) => ({
    userName: store.user.userName,
  }),
  (dispatch) => {
    return {
      signOut: () => {
        dispatch(signOut());
      },
      toggleSidebar: () => {
        dispatch(toggleSidebar());
      },
      debugBecomeCharityAdmin: () => {
        dispatch(debugBecomeCharityAdmin());
      },
      debugBecomeSystemAdmin: () => {
        dispatch(debugBecomeSystemAdmin());
      },
      debugBecomeContentManager: () => {
        dispatch(debugBecomeContentManager());
      },
      debugBecomeContentCreator: () => {
        dispatch(debugBecomeContentCreator());
      },
    };
  }
)(NavbarComponent);
