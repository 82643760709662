import { Col, Container, Row } from "reactstrap";

import LineChart from "./LineChart";
import React from "react";
import Statistics from "./Statistics";

export default class Default extends React.Component {
  render() {
    return (
      <Container fluid className="p-0">
        <Statistics />
        <Row>
          <Col lg="12">
            <LineChart />
          </Col>
        </Row>
      </Container>
    );
  }
}
