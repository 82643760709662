import * as types from "../constants";

import { reduce } from "./_genericReducer";

const initialState = {
  pageNumber: 1,
  supportersPerPage: 20,
  nameFilter: "",
  startDateFilter: null,
  endDateFilter: null,
  supporterTagFilter: "",
  childOrganizationFilters: [],
  videoPageContent: "test",
  shareMethod: null,
  recipients: [],
};

export default function reducer(state = initialState, actions) {
  if (actions.module === "SHARE") {
    const result = reduce(state, actions, initialState);
    if (result) return result;
  }

  switch (actions.type) {
    case types.ADD_MANY_RECIPIENTS:
      return {
        ...state,
        recipients: [...state.recipients, ...actions.recipients],
      };
    case types.REMOVE_RECIPIENT:
      return {
        ...state,
        recipients: state.recipients.filter((donor) => donor.id !== actions.id),
      };
    case types.SET_CHILDORGANIZATION_FILTERS:
      return {
        ...state,
        childOrganizationFilters: actions.childOrgs,
        pageNumber: 1,
      };
    default:
      return state;
  }
}
