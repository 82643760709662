export function setProp(moduleName, prop, value) {
  return {
    module: moduleName.toUpperCase(),
    type: "SET_PROP",
    prop: prop,
    value: value,
  };
}

export function toggleProp(moduleName, prop) {
  return {
    module: moduleName.toUpperCase(),
    type: "TOGGLE_PROP",
    prop: prop,
  };
}

export function appendArray(moduleName, arrayName, value) {
  return {
    module: moduleName.toUpperCase(),
    type: "APPEND_ARRAY",
    prop: arrayName,
    value: value,
  };
}

export function removeFromArray(moduleName, arrayName, value) {
  return {
    module: moduleName.toUpperCase(),
    type: "REMOVE_FROM_ARRAY",
    prop: arrayName,
    value: value,
  };
}

export function resetProps(moduleName) {
  return {
    module: moduleName.toUpperCase(),
    type: "RESET_PROPS",
  };
}
