import { sendRequest } from "../apiService";
import { setProp } from "../../redux/actions/genericActions";
import store from "../../redux/store";

export function getShareSendersEnabled(callback) {
  sendRequest({
    request: {
      resource: `/charities/me/share-senders/enabled`,
      method: "get",
    },
    success: (response) => {
      store.dispatch(
        setProp(
          "DASHBOARD",
          "showEmailSenders",
          response.data.isShareSendersEnabled
        )
      );
      if (callback) {
        callback(response.data);
      }
    },
  });
}

export function getShareSenders(callback) {
  sendRequest({
    request: {
      resource: `/charities/me/share-senders`,
      method: "get",
    },
    success: (response) => {
      if (callback) {
        callback(response.data);
      }
    },
  });
}

export function getShareSender(id, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/share-senders/${id}`,
      method: "get",
    },
    success: (response) => {
      if (callback) {
        callback(response.data);
      }
    },
  });
}

export function createShareSender(data, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/share-senders`,
      method: "post",
    },
    data,
    success: (response) => {
      if (callback) {
        const splitLocation = response.headers.location.split("/");
        const shareSenderId = splitLocation[splitLocation.length - 1];
        callback(shareSenderId);
      }
    },
  });
}

export function deleteShareSender(id, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/share-senders/${id}`,
      method: "delete",
    },
    success: (response) => {
      if (callback) {
        callback(response.data);
      }
    },
  });
}

export function updateShareSender(id, data, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/share-senders/${id}`,
      method: "put",
    },
    data,
    success: (response) => {
      if (callback) {
        callback(response.data);
      }
    },
  });
}
