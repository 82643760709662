import React, { useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useLocation,
} from "react-router-dom";
import {
  dashboard as dashboardRoutes,
  // landing as landingRoutes,
  page as pageRoutes,
  share as shareRoutes,
} from "./index";

import AuthLayout from "../layouts/Auth";
import DashboardLayout from "../layouts/Dashboard";
// import LandingLayout from "../layouts/Landing";
import Page404 from "../pages/shared/Page404";
import ScrollToTop from "../components/ScrollToTop";
import ShareLayout from "../layouts/Share";
import { logPageView } from "../services/analyticsService";

const childRoutes = (Layout, routes) =>
  routes.map(({ children, path, component: Component }, index) =>
    children ? (
      // Route item with children
      children.map(({ path, component: Component }, index) => (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        />
      ))
    ) : (
      // Route item without children
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    )
  );

function usePageViews() {
  let location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);
}

const Routes = () => (
  <Router>
    <ScrollToTop>
      <InnerRoutes />
    </ScrollToTop>
  </Router>
);

function InnerRoutes() {
  usePageViews();
  return (
    <Switch>
      {/* {childRoutes(LandingLayout, landingRoutes)} */}
      {childRoutes(DashboardLayout, dashboardRoutes)}
      {childRoutes(AuthLayout, pageRoutes)}
      {childRoutes(ShareLayout, shareRoutes)}
      <Route
        render={() => (
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Switch>
  );
}

export default Routes;
