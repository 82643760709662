import React from "react";
import { Redirect } from "react-router";
import Cookies from "js-cookie";
import { AUTH_ROUTES } from "../../routes/constants";

export default class SignUpForward extends React.Component {
  render() {
    Cookies.set("inviteKey", this.props.match.params.userId)

    return <Redirect to={AUTH_ROUTES.SIGN_UP} />;
  }
}
