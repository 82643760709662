import { sendRequest } from "../apiService";

export function getMyCharityApiKeys(callback) {
  sendRequest({
    request: {
      resource: "/charities/me/apikey/",
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function createApiKey(apiKey, callback) {
  sendRequest({
    request: {
      resource: "/charities/me/apikey",
      method: "post",
    },
    data: apiKey,
    success: (response) => {
      const splitLocation = response.headers.location.split("/");
      const apiKeyId = splitLocation[splitLocation.length - 1];
      callback(apiKeyId);
    },
  });
}

export function getApiKey(apiKeyId, callback) {
  sendRequest({
    request: {
      resource: "/charities/me/apikey/:apiKeyId".replace(":apiKeyId", apiKeyId),
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function updateApiKey(apiKeyId, apiKey, callback) {
  sendRequest({
    request: {
      resource: "/charities/me/apikey/:apiKeyId".replace(":apiKeyId", apiKeyId),
      method: "put",
    },
    data: apiKey,
    success: (response) => {
      callback(response);
    },
  });
}
