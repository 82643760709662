import Cookies from "js-cookie";
import { api } from "../connectionStrings";
import axios from "axios";
import { pushAlert } from "./alertService";
import { signOut } from "../redux/actions/userActions";
import store from "../redux/store";

export async function sendRequestAsync(model) {
  const url = api + model.request.resource;
  try {
    const response = await axios({
      method: model.request.method,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      onUploadProgress: function (progressEvent) {
        if (model.progressCallback) {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          model.progressCallback(percentCompleted);
        }
      },
      url: url,
      data: model.data,
      headers: { Authorization: "Bearer " + Cookies.get("apiKey") },
      config: model.config,
    });
    return response;
  } catch (error) {
    // 401 means the user's token expired on the backend. Need to re-login.
    if (error.response && error.response.status === 401) {
      store.dispatch(signOut());
    } else {
      var message = "An error occurred.";
      if (error.response && error.response.data.friendlyMessage != null) {
        message = error.response.data.friendlyMessage;
      }
      pushAlert({
        message: message,
        type: "error",
      });

      if (model.failure) {
        model.failure(error);
      }
    }
  }
}

export async function downloadFileAsync(model) {
  const url = api + model.request.resource;
  try {
    const response = await axios({
      method: model.request.method,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      url: url,
      data: model.data,
      responseType: "blob",
      headers: { Authorization: "Bearer " + Cookies.get("apiKey") },
      config: model.config,
    });

    if (model.success) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Results.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  } catch (error) {
    // 401 means the user's token expired on the backend. Need to re-login.
    if (error.response && error.response.status === 401) {
      store.dispatch(signOut());
    } else {
      var message = "An error occurred.";
      if (error.response && error.response.data.friendlyMessage != null) {
        message = error.response.data.friendlyMessage;
      }
      pushAlert({
        message: message,
        type: "error",
      });

      if (model.failure) {
        model.failure(error);
      }
    }
  }
}

export function sendRequest(model) {
  const url = api + model.request.resource;

  axios({
    method: model.request.method,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
    onUploadProgress: function (progressEvent) {
      if (model.progressCallback) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        model.progressCallback(percentCompleted);
      }
    },
    url: url,
    data: model.data,
    headers: { Authorization: "Bearer " + Cookies.get("apiKey") },
    config: model.config,
  })
    .then(function (response) {
      model.success ? model.success(response) : console.log(response);
    })
    .catch(function (error) {
      // 401 means the user's token expired on the backend. Need to re-login.
      if (error.response && error.response.status === 401) {
        store.dispatch(signOut());
      } else {
        var message = "An error occurred.";
        if (error.response && error.response.data.friendlyMessage != null) {
          message = error.response.data.friendlyMessage;
        }
        pushAlert({
          message: message,
          type: "error",
        });

        model.failure && model.failure(error);
      }
    });
}

export function downloadFile(model) {
  const url = api + model.request.resource;
  axios({
    method: model.request.method,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
    url: url,
    data: model.data,
    responseType: "blob",
    headers: { Authorization: "Bearer " + Cookies.get("apiKey") },
    config: model.config,
  })
    .then((response) => {
      //to who ever actually reads the pr this is a little janky. we basically receive the blob and then create a link to the blob and click it to initiate download
      if (model.success) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Results.xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    })
    .catch(function (error) {
      // 401 means the user's token expired on the backend. Need to re-login.
      if (error.response && error.response.status === 401) {
        store.dispatch(signOut());
      } else {
        var message = "An error occurred.";
        if (error.response && error.response.data.friendlyMessage != null) {
          message = error.response.data.friendlyMessage;
        }
        pushAlert({
          message: message,
          type: "error",
        });

        model.failure && model.failure(error);
      }
    });
}
