import { sendRequest } from "../apiService";

//BEGIN ADMIN USER

export function getUsers(charityId, callback) {
  sendRequest({
    request: {
      resource: "/administration/charities/:charity/users/".replace(
        ":charity",
        charityId
      ),
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function getUser(charityId, userId, callback) {
  sendRequest({
    request: {
      resource: "/administration/charities/:charity/users/:user"
        .replace(":charity", charityId)
        .replace(":user", userId),
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function adminInviteUser(charityId, user, callback) {
  sendRequest({
    request: {
      resource: "/administration/charities/:charity/users".replace(
        ":charity",
        charityId
      ),
      method: "post",
    },
    data: user,
    success: (response) => {
      callback(response);
    },
  });
}

export function resendCharityUserInviteEmail(charityId, userId, callback) {
  sendRequest({
    request: {
      resource: "/administration/charities/:charity/users/invites/send/:user"
        .replace(":charity", charityId)
        .replace(":user", userId),
      method: "post",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function resetCredentials(charityId, userId, callback) {
  sendRequest({
    request: {
      resource:
        "/administration/charities/:charity/users/:userId/reset-credentials"
          .replace(":charity", charityId)
          .replace(":userId", userId),
      method: "post",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function updateUser(charityId, userId, data, callback) {
  sendRequest({
    request: {
      resource: "/administration/charities/:charity/users/:user"
        .replace(":charity", charityId)
        .replace(":user", userId),
      method: "put",
    },
    data: data,
    success: (response) => {
      callback(response);
    },
  });
}

//BEGIN CHARITY USER

export function getMyCharityUsers(callback) {
  sendRequest({
    request: {
      resource: "/charities/me/users/",
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function getMyUser(userId, callback) {
  sendRequest({
    request: {
      resource: "/charities/me/users/:user".replace(":user", userId),
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function inviteUser(user, callback) {
  sendRequest({
    request: {
      resource: "/charities/me/users",
      method: "post",
    },
    data: user,
    success: (response) => {
      callback(response);
    },
  });
}

export function updateMyUser(userId, user, callback) {
  sendRequest({
    request: {
      resource: "/charities/me/users/:user".replace(":user", userId),
      method: "put",
    },
    data: user,
    success: (response) => {
      callback(response);
    },
  });
}

export function resendMyUserInviteEmail(userId, callback) {
  sendRequest({
    request: {
      resource: "/charities/me/users/invites/send/:user".replace(
        ":user",
        userId
      ),
      method: "post",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

//BEGIN CHARITY CHILD USER

export function getCharityChildUsers(charityId, callback) {
  sendRequest({
    request: {
      resource: "/charities/me/children/:charityId/users/".replace(
        ":charityId",
        charityId
      ),
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function getCharityChildUser(charityId, userId, callback) {
  sendRequest({
    request: {
      resource: "/charities/me/children/:charityId/users/:userId"
        .replace(":charityId", charityId)
        .replace(":userId", userId),
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function inviteCharityChildUser(charityId, user, callback) {
  sendRequest({
    request: {
      resource: "/charities/me/children/:charityId/users".replace(
        ":charityId",
        charityId
      ),
      method: "post",
    },
    data: user,
    success: (response) => {
      callback(response);
    },
  });
}

export function updateCharityChildUser(charityId, userId, user, callback) {
  sendRequest({
    request: {
      resource: "/charities/me/children/:charityId/users/:userId"
        .replace(":charityId", charityId)
        .replace(":userId", userId),
      method: "put",
    },
    data: user,
    success: (response) => {
      callback(response);
    },
  });
}

export function resendChildCharityUserInviteEmail(charityId, userId, callback) {
  sendRequest({
    request: {
      resource: "/charities/me/children/:charityId/users/:userId/invites"
        .replace(":charityId", charityId)
        .replace(":userId", userId),
      method: "post",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}
