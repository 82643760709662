import * as types from "../constants";

export function setDirty(isDirty) {
  return {
    type: types.SET_CHARITIES_DIRTY,
    isDirty: isDirty,
  };
}
export function setCharities(charities) {
  return {
    type: types.SET_CHARITIES,
    charities: charities,
  };
}
