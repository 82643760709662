import * as types from "../constants";

export function setDirty(isDirty) {
  return {
    type: types.SET_MEDIA_DIRTY,
    isDirty: isDirty,
  };
}

export function editMedium(medium) {
  return {
    type: types.EDIT_MEDIA,
    medium: medium,
  };
}

export function setMedia(media) {
  return {
    type: types.SET_MEDIA,
    media: media,
  };
}

export function setTagFilters(tags) {
  return {
    type: types.SET_TAG_FILTERS,
    tags: tags,
  };
}

export function setChildOrganizationFilters(childOrgs) {
  return {
    type: types.SET_CHILDORGANIZATION_FILTERS,
    childOrgs: childOrgs,
  };
}

export function setStartDateFilter(startDate) {
  return {
    type: types.SET_START_DATE_FILTER,
    startDate: startDate,
  };
}

export function setEndDateFilter(endDate) {
  return {
    type: types.SET_END_DATE_FILTER,
    endDate: endDate,
  };
}

export function setStatusFilters(statuses) {
  return {
    type: types.SET_STATUS_FILTERS,
    statuses: statuses,
  };
}

export function toggleStatusFilter(status) {
  return {
    type: types.TOGGLE_STATUS_FILTER,
    status: status,
  };
}

export function setPageNumber(pageNumber) {
  return {
    type: types.SET_PAGE_NUMBER,
    pageNumber: pageNumber,
  };
}
