import { Card, CardBody, Col, Media, Row } from "reactstrap";
import { Eye, Send, Video } from "react-feather";

import React from "react";
import _ from "lodash";
import { connect } from "react-redux";

const Statistics = ({ sharesSent, mediaUploaded, sharesViewed }) => (
  <Row>
    <Col md="4">
      <Card className="flex-fill">
        <CardBody className="py-4">
          <Media>
            <div className="d-inline-block mt-2 mr-3">
              <Video className="feather-lg text-primary" />
            </div>
            <Media body>
              <h3 className="mb-2">{mediaUploaded}</h3>
              <div className="mb-0">Photos and Videos Uploaded</div>
            </Media>
          </Media>
        </CardBody>
      </Card>
    </Col>
    <Col md="4">
      <Card className="flex-fill">
        <CardBody className="py-4">
          <Media>
            <div className="d-inline-block mt-2 mr-3">
              <Send className="feather-lg text-success" />
            </div>
            <Media body>
              <h3 className="mb-2">{sharesSent}</h3>
              <div className="mb-0">Total Shares Sent</div>
            </Media>
          </Media>
        </CardBody>
      </Card>
    </Col>
    <Col md="4">
      <Card className="flex-fill">
        <CardBody className="py-4">
          <Media>
            <div className="d-inline-block mt-2 mr-3">
              <Eye className="feather-lg text-info" />
            </div>
            <Media body>
              <h3 className="mb-2">{sharesViewed}</h3>
              <div className="mb-0">Total Shares Viewed</div>
            </Media>
          </Media>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

export default connect((store) => ({
  mediaUploaded: store.dashboard.mediaUploaded,
  sharesSent: store.dashboard.sharesSent,
  sharesViewed: store.dashboard.sharesViewed,
}))(Statistics);
