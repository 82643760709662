import {
  infoRoutes,
  nonSidebarPublicRoutes,
  shareRoutes,
  sidebarPublicRoutes,
} from "./publicRoutes";
import { nonSidebarAdminRoutes, sidebarAdminRoutes } from "./adminRoutes";

import { authRoutes } from "./authRoutes";
// import { landingRoutes } from "./landingRoutes";
import { layoutRoutes } from "./layoutRoutes";

// Pages that use the "Dashboard" Template
export const dashboard = [
  nonSidebarAdminRoutes,
  ...sidebarAdminRoutes,
  nonSidebarPublicRoutes,
  ...sidebarPublicRoutes,
  layoutRoutes,
];

// Pages that use the "Landing Page" template
// export const landing = [landingRoutes];

// Pages that use the "Page" template
export const page = [authRoutes, infoRoutes];

// Pages that use the "Share" template (has a white background instead of light blue)
export const share = [shareRoutes];

// Pages displayed in the sidebar
export default [...sidebarAdminRoutes, ...sidebarPublicRoutes];

// import {
//   ACCOUNT_ROUTES,
//   ADMIN_ROUTES,
//   AUTH_ROUTES,
//   DASHBOARD,
//   DONATION_ROUTES,
//   DONOR_ROUTES,
//   INFO_ROUTES,
//   LANDING,
//   MEDIA_ROUTES,
//   MY_LOCKER,
//   SHARE_ROUTES,
// } from "../routes/constants.js";
// import { ADMIN_ROLES, PUBLIC_ROLES } from "../roleConstants";
// import {
//   Award as AwardIcon,
//   DollarSign as DollarSignIcon,
//   Folder as FolderIcon,
//   Gift as GiftIcon,
//   Layout as LayoutIcon,
//   Monitor as MonitorIcon,
//   PieChart as PieChartIcon,
//   Settings as SettingsIcon,
//   User as UserIcon,
//   Users as UsersIcon,
// } from "react-feather";

// import Boxed from "../pages/layouts/Boxed";
// import Landing from "../pages/landing/Landing";
// import Page404 from "../pages/shared/Page404";
// import Page500 from "../pages/shared/Page500";
// import Pricing from "../pages/public/Pricing";
// import Profile from "../pages/public/Profile";
// import ResetPassword from "../pages/shared/ResetPassword";
// import SidebarCollapsed from "../pages/layouts/SidebarCollapsed";
// import SidebarSticky from "../pages/layouts/SidebarSticky";
// import SignIn from "../pages/shared/SignIn";
// import SignUp from "../pages/shared/SignUp";
// import SignUpForward from "../pages/shared/SignUpForward";
// import ThemeClassic from "../pages/layouts/ThemeClassic";
// import ThemeCorporate from "../pages/layouts/ThemeCorporate";
// import ThemeModern from "../pages/layouts/ThemeModern";
// import Unsubscribed from "../pages/shared/Unsubscribed";
// import async from "../components/Async";

// // Landing

// // Auth

// // Layouts

// // Pages

// // Route Constants

// // Dashboards
// const Default = async(() => import("../pages/layouts/Dashboard"));

// // Admin
// const AdminCharities = async(() => import("../pages/admin/Charities"));
// const AdminCharityCreate = async(() => import("../pages/admin/CharityCreate"));
// const AdminCharityEdit = async(() => import("../pages/admin/CharityEdit"));
// const AdminCharityUsage = async(() =>
//   import("../pages/admin/OrganizationUsage")
// );
// const AdminSystemAdminEdit = async(() =>
//   import("../pages/admin/SystemAdminEdit")
// );
// const AdminSystemAdmins = async(() => import("../pages/admin/SystemAdmins"));
// const CreateSystemAdmin = async(() =>
//   import("../pages/admin/SystemAdminCreate")
// );
// const InviteCharityUser = async(() =>
//   import("../pages/admin/InviteCharityUser")
// );
// const AdminEditCharityUser = async(() =>
//   import("../pages/admin/EditCharityUser")
// );

// // Public
// const PublicCharityEdit = async(() => import("../pages/public/CharityEdit"));
// const Users = async(() => import("../pages/public/Users"));
// const Locker = async(() => import("../pages/public/Locker"));
// const InviteUser = async(() => import("../pages/public/InviteUser"));
// const EditUser = async(() => import("../pages/public/EditUser"));
// const PublicMediaEdit = async(() => import("../pages/public/Media/Edit"));
// const PublicMediaCreate = async(() => import("../pages/public/Media/Create"));
// const PublicShareCreate = async(() => import("../pages/public/Share/Create"));
// const PublicSharePreview = async(() => import("../pages/public/Share/Preview"));
// const PublicShareDetails = async(() => import("../pages/public/Share/Details"));
// const PublicDonations = async(() =>
//   import("../pages/public/Donations/Donations")
// );
// const PublicDonors = async(() => import("../pages/public/Donors/Donors"));
// const PublicDonorsCreate = async(() => import("../pages/public/Donors/Create"));
// const PublicDonorsUpdate = async(() => import("../pages/public/Donors/Update"));
// const PublicDonorsDetail = async(() => import("../pages/public/Donors/Detail"));
// const PublicDonationsImport = async(() =>
//   import("../pages/public/Donations/Import")
// );

// // Shared
// const About = async(() => import("../pages/shared/About"));
// const PrivacyPolicy = async(() => import("../pages/shared/PrivacyPolicy"));

// // Admin Sidebar Routes
// const adminCharityRoutes = {
//   path: ADMIN_ROUTES.CHARITIES,
//   name: "Organizations",
//   component: AdminCharities,
//   icon: GiftIcon,
//   roles: Object.values(ADMIN_ROLES),
//   children: null,
// };

// const adminSystemAdminRoutes = {
//   path: ADMIN_ROUTES.SYSTEM_ADMINS,
//   name: "System Admins",
//   component: AdminSystemAdmins,
//   icon: UsersIcon,
//   roles: Object.values(ADMIN_ROLES),
//   children: null,
// };

// const adminOrganizationUsage = {
//   path: ADMIN_ROUTES.CHARITY_ORGANIZATION_USAGE,
//   name: "Usage",
//   component: AdminCharityUsage,
//   icon: UsersIcon,
//   roles: Object.values(ADMIN_ROLES),
//   children: null,
// };

// // Public Sidebar Routes
// const dashboardRoutes = {
//   path: DASHBOARD,
//   name: "Dashboard",
//   icon: LayoutIcon,
//   component: Default,
//   roles: Object.values(PUBLIC_ROLES),
//   children: null,
// };

// const lockerRoutes = {
//   path: MY_LOCKER,
//   name: "My Locker",
//   icon: FolderIcon,
//   component: Locker,
//   roles: Object.values(PUBLIC_ROLES),
//   children: null,
//   badgeColor: "warning",
//   badgeData: "pendingApprovalCount",
// };

// // const donationRoutes = {
// //   path: DONATION_ROUTES.DONATIONS,
// //   name: "Contributions",
// //   icon: AwardIcon,
// //   component: PublicDonations,
// //   roles: [PUBLIC_ROLES.ADMINISTRATOR],
// //   children: null,
// // };

// const donorRoutes = {
//   path: DONOR_ROUTES.DONORS,
//   name: "Supporters",
//   icon: UsersIcon,
//   component: PublicDonors,
//   roles: [PUBLIC_ROLES.ADMINISTRATOR],
//   children: null,
// };

// const accountRoutes = {
//   path: "/account",
//   name: "My Account",
//   icon: SettingsIcon,
//   roles: [PUBLIC_ROLES.ADMINISTRATOR],
//   children: [
//     {
//       path: ACCOUNT_ROUTES.MY_CHARITY,
//       name: "My Organization",
//       component: PublicCharityEdit,
//     },
//     {
//       path: ACCOUNT_ROUTES.USERS,
//       name: "Users",
//       component: Users,
//     },
//     // {
//     //   path: ACCOUNT_ROUTES.SUBSCRIPTION,
//     //   name: "Subscription",
//     //   component: Pricing
//     // },
//   ],
// };

// const landingRoutes = {
//   path: "gone",
//   name: "Landing Page",
//   component: Landing,
//   children: null,
// };

// const staticRoutes = {
//   path: "/info",
//   name: "Info",
//   icon: UserIcon,
//   children: [
//     {
//       path: INFO_ROUTES.ABOUT,
//       name: "About",
//       component: About,
//     },
//     // {
//     //   path: INFO_ROUTES.PRIVACY_POLICY,
//     //   name: "Privacy Policy",
//     //   component: PrivacyPolicy
//     // }
//   ],
// };

// const authRoutes = {
//   path: "/auth",
//   name: "Auth",
//   icon: UsersIcon,
//   badgeColor: "secondary",
//   badgeText: "12/24",
//   children: [
//     {
//       path: AUTH_ROUTES.SIGN_IN,
//       name: "Sign In",
//       component: SignIn,
//     },
//     {
//       path: AUTH_ROUTES.SIGN_UP,
//       name: "Sign Up",
//       component: SignUp,
//     },
//     {
//       path: AUTH_ROUTES.SIGN_UP_FORWARD,
//       name: "Sign Up",
//       component: SignUpForward,
//     },
//     // {
//     //   path: "/auth/reset-password",
//     //   name: "Reset Password",
//     //   component: ResetPassword
//     // },
//     {
//       path: AUTH_ROUTES.ERROR_404,
//       name: "404 Page",
//       component: Page404,
//     },
//     {
//       path: AUTH_ROUTES.ERROR_500,
//       name: "500 Page",
//       component: Page500,
//     },
//   ],
// };

// const infoRoutes = {
//   path: "/info",
//   name: "Info",
//   icon: UserIcon,
//   children: [
//     {
//       path: INFO_ROUTES.UNSUBSCRIBED,
//       name: "Unsubscribed",
//       component: Unsubscribed,
//     },
//   ],
// };

// const shareRoutes = {
//   name: "share",
//   icon: UserIcon,
//   children: [
//     {
//       path: SHARE_ROUTES.PREVIEW,
//       name: "Share Preview",
//       roles: Object.values(PUBLIC_ROLES),
//       component: PublicSharePreview,
//     },
//     {
//       path: SHARE_ROUTES.DETAILS,
//       name: "Share Details",
//       component: PublicShareDetails,
//     },
//   ],
// };

// const outsideRoutes = {
//   children: [
//     {
//       path: LANDING,
//       component: () => {
//         window.location.href = process.env.REACT_APP_LANDING_REDIRECT;
//         return null;
//       },
//     },
//     {
//       path: INFO_ROUTES.PRIVACY_POLICY,
//       component: () => {
//         window.location.href = process.env.REACT_APP_PRIVACY_POLICY_REDIRECT;
//         return null;
//       },
//     },
//   ],
// };

// const nonSidebarDashboardRoutes = {
//   children: [
//     {
//       path: ADMIN_ROUTES.EDIT_CHARITY,
//       name: "Edit Organization",
//       component: AdminCharityEdit,
//     },
//     {
//       path: ADMIN_ROUTES.EDIT_CHARITY_USER,
//       name: "Edit User",
//       component: AdminEditCharityUser,
//     },
//     {
//       path: ADMIN_ROUTES.INVITE_CHARITY_USER,
//       name: "Invite Organization User",
//       component: InviteCharityUser,
//     },
//     {
//       path: ADMIN_ROUTES.CREATE_CHARITY,
//       name: "Create Organization",
//       component: AdminCharityCreate,
//     },
//     {
//       path: ADMIN_ROUTES.EDIT_SYSTEM_ADMIN,
//       name: "Edit System Admin",
//       component: AdminSystemAdminEdit,
//     },
//     {
//       path: ADMIN_ROUTES.CREATE_SYSTEM_ADMIN,
//       name: "Create System Admin",
//       component: CreateSystemAdmin,
//     },
//     {
//       path: MEDIA_ROUTES.EDIT,
//       name: "Edit Media",
//       component: PublicMediaEdit,
//     },
//     {
//       path: DONOR_ROUTES.CREATE,
//       name: "Create Donor",
//       component: PublicDonorsCreate,
//     },
//     {
//       path: DONOR_ROUTES.UPDATE,
//       name: "Update Donor",
//       component: PublicDonorsUpdate,
//     },
//     {
//       path: SHARE_ROUTES.CREATE,
//       name: "Share Media",
//       component: PublicShareCreate,
//     },
//     {
//       path: MEDIA_ROUTES.CREATE,
//       name: "Create Media",
//       component: PublicMediaCreate,
//     },
//     {
//       path: DONATION_ROUTES.IMPORT_DONATIONS,
//       name: "Import Donations",
//       component: PublicDonationsImport,
//     },
//     {
//       path: DONOR_ROUTES.DONOR_DETAIL,
//       name: "Supporter Detail",
//       component: PublicDonorsDetail,
//     },
//   ],
// };

// const pageRoutes = {
//   path: "/pages",
//   name: "Pages",
//   icon: LayoutIcon,
//   children: [
//     {
//       path: ACCOUNT_ROUTES.INVITE_USER,
//       name: "Invite New User",
//       component: InviteUser,
//     },
//     {
//       path: ACCOUNT_ROUTES.EDIT_USER,
//       name: "Edit User",
//       component: EditUser,
//     },
//   ],
// };

// const layoutRoutes = {
//   path: "/layouts",
//   name: "Layouts",
//   icon: MonitorIcon,
//   children: [
//     {
//       path: "/layouts/sidebar-sticky",
//       name: "Sticky Sidebar",
//       component: SidebarSticky,
//     },
//     {
//       path: "/layouts/sidebar-collapsed",
//       name: "Sidebar Collapsed",
//       component: SidebarCollapsed,
//     },
//     {
//       path: "/layouts/boxed",
//       name: "Boxed Layout",
//       component: Boxed,
//     },
//     {
//       path: "/layouts/theme-classic",
//       name: "Classic Theme",
//       component: ThemeClassic,
//     },
//     {
//       path: "/layouts/theme-corporate",
//       name: "Corporate Theme",
//       component: ThemeCorporate,
//       badgeColor: "primary",
//       badgeText: "New",
//     },
//     {
//       path: "/layouts/theme-modern",
//       name: "Modern Theme",
//       component: ThemeModern,
//       badgeColor: "primary",
//       badgeText: "New",
//     },
//   ],
// };

// // Dashboard-template routes
// export const dashboard = [
//   adminCharityRoutes,
//   adminSystemAdminRoutes,
//   adminOrganizationUsage,
//   nonSidebarDashboardRoutes,
//   accountRoutes,
//   dashboardRoutes,
//   pageRoutes,
//   layoutRoutes,
//   lockerRoutes,
//   //donationRoutes,
//   donorRoutes,
// ];

// // Landing-page-template routes
// export const landing = [landingRoutes, staticRoutes];

// // Auth-template routes
// export const page = [authRoutes, infoRoutes, outsideRoutes];

// export const share = [shareRoutes];

// // Routes displayed in a sidebar
// export default [
//   dashboardRoutes,
//   adminCharityRoutes,
//   adminSystemAdminRoutes,
//   adminOrganizationUsage,
//   lockerRoutes,
//   //donationRoutes,
//   donorRoutes,
//   accountRoutes,
// ];
