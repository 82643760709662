import {
  Gift as GiftIcon,
  Users as UsersIcon,
  BookOpen,
  FileText,
} from "react-feather";

import { ADMIN_ROLES } from "../roleConstants";
import { ADMIN_ROUTES } from "../routes/constants.js";
import async from "../components/Async";

const AdminCharities = async(() => import("../pages/admin/Charities"));
const AdminCharityCreate = async(() => import("../pages/admin/CharityCreate"));
const AdminCharityEdit = async(() => import("../pages/admin/CharityEdit"));
const AdminSystemAdminEdit = async(() =>
  import("../pages/admin/SystemAdminEdit")
);
const AdminSystemAdmins = async(() => import("../pages/admin/SystemAdmins"));
const CreateSystemAdmin = async(() =>
  import("../pages/admin/SystemAdminCreate")
);
const InviteCharityUser = async(() =>
  import("../pages/admin/InviteCharityUser")
);
const AdminEditCharityUser = async(() =>
  import("../pages/admin/EditCharityUser")
);

const AdminCharityUsage = async(() =>
  import("../pages/admin/OrganizationUsage")
);

const AdminCharityUsageDetails = async(() =>
  import("../pages/admin/OrganizationUsageDetails")
);

//templates
//------------------------------------------------

//request
const AdminMediaRequestTemplates = async(() =>
  import("../pages/admin/templates/request/All")
);

const AdminMediaRequestTemplatesCreate = async(() =>
  import("../pages/admin/templates/request/Create")
);
const AdminMediaRequestTemplateEdit = async(() =>
  import("../pages/admin/templates/request/Edit")
);

//share
const AdminMediaShareTemplates = async(() =>
  import("../pages/admin/templates/share/All")
);

const AdminMediaShareTemplatesCreate = async(() =>
  import("../pages/admin/templates/share/Create")
);
const AdminMediaShareTemplateEdit = async(() =>
  import("../pages/admin/templates/share/Edit")
);

// Pages Displayed In Sidebar
export const sidebarAdminRoutes = [
  {
    path: ADMIN_ROUTES.CHARITIES,
    name: "Organizations",
    component: AdminCharities,
    icon: GiftIcon,
    roles: Object.values(ADMIN_ROLES),
    children: null,
  },
  {
    path: ADMIN_ROUTES.SYSTEM_ADMINS,
    name: "System Admins",
    component: AdminSystemAdmins,
    icon: UsersIcon,
    roles: Object.values(ADMIN_ROLES),
    children: null,
  },
  {
    path: ADMIN_ROUTES.CHARITY_ORGANIZATION_USAGE,
    name: "Usage",
    component: AdminCharityUsage,
    icon: BookOpen,
    roles: Object.values(ADMIN_ROLES),
    children: null,
  },
  {
    path: ADMIN_ROUTES.SHARE_TEMPLATES,
    name: "Media Templates",
    component: AdminMediaShareTemplates,
    icon: FileText,
    roles: Object.values(ADMIN_ROLES),
    children: null,
  },
  {
    path: ADMIN_ROUTES.REQUEST_TEMPLATES,
    name: "Request Templates",
    component: AdminMediaRequestTemplates,
    icon: FileText,
    roles: Object.values(ADMIN_ROLES),
    children: null,
  },
];

// Pages Not Displayed In Sidebar
export const nonSidebarAdminRoutes = {
  children: [
    {
      path: ADMIN_ROUTES.EDIT_CHARITY,
      name: "Edit Charity",
      component: AdminCharityEdit,
    },
    {
      path: ADMIN_ROUTES.EDIT_CHARITY_USER,
      name: "Edit User",
      component: AdminEditCharityUser,
    },
    {
      path: ADMIN_ROUTES.INVITE_CHARITY_USER,
      name: "Invite Charity User",
      component: InviteCharityUser,
    },
    {
      path: ADMIN_ROUTES.CREATE_CHARITY,
      name: "Create Charity",
      component: AdminCharityCreate,
    },
    {
      path: ADMIN_ROUTES.EDIT_SYSTEM_ADMIN,
      name: "Edit System Admin",
      component: AdminSystemAdminEdit,
    },
    {
      path: ADMIN_ROUTES.CREATE_SYSTEM_ADMIN,
      name: "Create System Admin",
      component: CreateSystemAdmin,
    },
    {
      path: ADMIN_ROUTES.CHARITY_USAGE_DETAILS,
      name: "Usage Details",
      component: AdminCharityUsageDetails,
    },

    {
      path: ADMIN_ROUTES.REQUEST_TEMPLATES_CREATE,
      name: "Create Media Request Template",
      component: AdminMediaRequestTemplatesCreate,
    },
    {
      path: ADMIN_ROUTES.REQUEST_TEMPLATE_EDIT,
      name: "Edit Media Request Template",
      component: AdminMediaRequestTemplateEdit,
    },

    {
      path: ADMIN_ROUTES.SHARE_TEMPLATES_CREATE,
      name: "Create Media Share Template",
      component: AdminMediaShareTemplatesCreate,
    },
    {
      path: ADMIN_ROUTES.SHARE_TEMPLATE_EDIT,
      name: "Edit Media Share Template",
      component: AdminMediaShareTemplateEdit,
    },
  ],
};
