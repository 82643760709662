import React, { Component } from "react";
import { Button } from "reactstrap";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GoogleLogin from "react-google-login";

export default class Google extends Component {
  state = {
    isLoggedIn: false,
    userID: "",
  };

  onSuccess = (response) => {
    this.props.successCallback(response);
  };

  onFailure = (error) => {};

  render() {
    return (
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_OAUTH_ID}
        buttonText="Login with Google"
        onFailure={this.onFailure}
        onSuccess={this.onSuccess}
        cookiePolicy={"single_host_origin"}
        render={(renderProps) => (
          <Button
            color="google"
            size="lg"
            className="mr-1 mb-1"
            onClick={renderProps.onClick}
          >
            <FontAwesomeIcon icon={faGoogle} className="align-middle" /> Google
          </Button>
        )}
      />
    );
  }
}
