import * as types from "../constants";

import { STATUSES } from "../../mediaStatusConstants";

const initialState = {
  isDirty: true,
  media: [],
  tagFilters: [],
  childOrganizationFilters: [],
  startDateFilter: "",
  endDateFilter: "",
  statusFilters: [STATUSES.APPROVED, STATUSES.PENDING],
  itemsPerPage: 21,
  pageNumber: 1,
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.EDIT_MEDIA:
      var newMedia = [...state.media];
      const index = state.media.findIndex((a) => a.id === actions.medium.id);
      newMedia[index] = actions.medium;
      return {
        ...state,
        media: newMedia,
      };

    case types.SET_MEDIA:
      return {
        ...state,
        media: actions.media,
      };

    case types.SET_MEDIA_DIRTY:
      return {
        ...state,
        isDirty: actions.isDirty,
      };

    case types.SET_TAG_FILTERS:
      return {
        ...state,
        tagFilters: actions.tags,
        pageNumber: 1,
      };

    case types.SET_CHILDORGANIZATION_FILTERS:
      return {
        ...state,
        childOrganizationFilters: actions.childOrgs,
        pageNumber: 1,
      };

    case types.SET_START_DATE_FILTER:
      return {
        ...state,
        startDateFilter: actions.startDate,
        pageNumber: 1,
      };

    case types.SET_END_DATE_FILTER:
      return {
        ...state,
        endDateFilter: actions.endDate,
        pageNumber: 1,
      };

    case types.SET_STATUS_FILTERS:
      return {
        ...state,
        statusFilters: actions.statuses,
        pageNumber: 1,
      };

    case types.TOGGLE_STATUS_FILTER:
      let newStatusFilters = [...state.statusFilters];

      if (newStatusFilters.includes(actions.status)) {
        newStatusFilters = newStatusFilters.filter((status) => {
          return status !== actions.status;
        });
      } else {
        newStatusFilters = [...newStatusFilters, actions.status];
      }

      return {
        ...state,
        statusFilters: newStatusFilters,
        pageNumber: 1,
      };

    case types.SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: actions.pageNumber,
      };

    default:
      return state;
  }
}
