import * as types from "../constants";

import { reduce } from "./_genericReducer";

const initialState = {
  pageNumber: 1,
  unsubscribedPageNumber: 1,
  itemsPerPage: 20,
  unsubscribedItemsPerPage: 20,
  isDirty: true,
  donations: [],
  donors: [],
  nameFilter: "",
  unsubscribedNameFilter: "",
  tagFilter: "",
  startDateFilter: "",
  endDateFilter: "",
  selectedDonors: [],
  childOrganizationFilters: [],
  sortBy: "lastName",
  sortOrder: "asc",
  unsubscribedSortBy: "lastName",
  unsubscribedSortOrder: "asc",
};

export default function reducer(state = initialState, actions) {
  if (actions.module === "DONATIONS") {
    const result = reduce(state, actions, initialState);
    if (result) return result;
  }

  switch (actions.type) {
    // case types.SET_DONATIONS:
    //   return {
    //     ...state,
    //     donations: actions.donations.map((donation) => {
    //       return { ...donation, date: new Date(donation.date) };
    //     }),
    //   };
    case types.SET_DONATIONS_DIRTY:
      return {
        ...state,
        isDirty: actions.isDirty,
      };
    case types.SET_DONATIONS_NAME_FILTER:
      return {
        ...state,
        nameFilter: actions.nameFilter,
      };
    case types.SET_DONATIONS_END_DATE_FILTER:
      return {
        ...state,
        endDateFilter: actions.endDate,
      };
    case types.SET_DONATIONS_START_DATE_FILTER:
      return {
        ...state,
        startDateFilter: actions.startDate,
      };
    case types.SET_CHILDORGANIZATION_FILTERS:
      return {
        ...state,
        childOrganizationFilters: actions.childOrgs,
        pageNumber: 1,
      };

    default:
      return state;
  }
}
