import { sendRequest } from "../apiService";

export function signIn(data, callback) {
  sendRequest({
    request: {
      resource: "/sessions/me/",
      method: "post",
    },
    data: { ...data, source: "web" },
    success: (response) => {
      callback(response);
    },
  });
}
export function signUp(data, callback) {
  sendRequest({
    request: {
      resource: "/sessions/invitations/",
      method: "post",
    },
    data: data,
    success: (response) => {
      callback(response);
    },
  });
}
export function getInviteData(userId, callback) {
  sendRequest({
    request: {
      resource: `/sessions/invitations/${userId}`,
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function changePassword(data, callback) {
  sendRequest({
    request: {
      resource: "/sessions/me/password",
      method: "put",
    },
    data: data,
    success: (response) => {
      callback(response);
    },
  });
}

export function resetPassword(data, callback) {
  sendRequest({
    request: {
      resource: "/sessions/password/reset",
      method: "post",
    },
    data: data,
    success: (response) => {
      callback(response);
    },
  });
}
