import * as types from "../constants";

export function addAdmin(admin) {
  return {
    type: types.ADD_ADMIN,
    admin: admin
  };
}

export function setDirty(isDirty) {
  return {
    type: types.SET_ADMINS_DIRTY,
    isDirty: isDirty
  };
}

export function editAdmin(admin) {
  return {
    type: types.EDIT_ADMIN,
    admin: admin
  };
}

export function setAdmins(admins) {
  return {
    type: types.SET_ADMINS,
    admins: admins
  };
}
