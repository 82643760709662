import { reduce } from "./_genericReducer";

const initialState = {
  mediaUploaded: 0,
  sharesSent: 0,
  sharesViewed: 0,
  twelveMonthShares: [],
  twelveMonthViews: [],
  showEmailSenders: true,
  charityName: "",
  hasAutomations: false,
};

export default function reducer(state = initialState, actions) {
  if (actions.module === "DASHBOARD") {
    const result = reduce(state, actions, initialState);
    if (result) return result;
  }

  let newState = { ...state };

  switch (actions.type) {
    default:
      return state;
  }
}
