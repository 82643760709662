import {sendRequest} from "../apiService";

export function getUserRoles(callback) {
  sendRequest({
    request: {
      resource: "/charities/roles/",
      method: "get"
    },
    data: null,
    success: response => {
      callback(response);
    }
  });
}