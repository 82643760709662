import * as types from "../constants";

const initialState = {
  isDirty: true,
  admins: [],
};

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.ADD_ADMIN:
      return {
        ...state,
        admins: [...state.admins, actions.admin]
      };

    case types.EDIT_ADMIN:
      var newAdmins = [...state.admins];
      const index = state.admins.findIndex(a => a.id === actions.admin.id);
      newAdmins[index] = actions.admin;
      return {
        ...state,
        admins: newAdmins
      };

    case types.SET_ADMINS:
      return {
        ...state,
        admins: actions.admins
      }

    case types.SET_ADMINS_DIRTY:
      return{
        ...state,
        isDirty: actions.isDirty
      }

    default:
      return state;
  }
}
