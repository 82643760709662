import React from "react";
import { Redirect } from "react-router";

import { AvField, AvForm} from "availity-reactstrap-validation";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Row,
} from "reactstrap";

import { resetPassword } from "../../services/apiActions";
import { AUTH_ROUTES } from "../../routes/constants";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
    };
  }

  render() {
    if (this.state.redirect === true) {
      return <Redirect push to={AUTH_ROUTES.SIGN_IN} />;
    }

    return (
      <React.Fragment>
        <div className="text-center mt-4">
          <h1 className="h2">Reset password</h1>
          <p className="lead">
            Enter your email to reset your password. If there is an account
            associated with with this email address a new temporary password
            will be emailed to you.
          </p>
        </div>
        <Card>
          <CardBody>
            <div className="m-sm-4">
              <AvForm
                model={{}}
                onValidSubmit={(event, formValues) => {
                  resetPassword({ email: formValues.email }, () => {
                    this.setState({ redirect: true });
                  });
                }}
              >
                <Row>
                  <Col>
                    <AvField name="email" label="Email" type="email" required />
                  </Col>
                </Row>
                <FormGroup className="float-right">
                  <Button className="mr-1 mb-1">Reset Password</Button>
                </FormGroup>
              </AvForm>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

export default ResetPassword;
