import React, { Component } from "react";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FacebookProvider, LoginButton } from "react-facebook";

export default class Facebook extends Component {
  state = {
    isLoggedIn: false,
    userID: "",
  };

  handleResponse = (response) => {
    this.props.successCallback(response.tokenDetail);
  };

  handleError = (response) => {};

  render() {
    return (
      <FacebookProvider appId={process.env.REACT_APP_FACEBOOK_OAUTH_ID}>
        <LoginButton
          scope="email"
          onCompleted={this.handleResponse}
          onError={this.handleError}
          className="mr-1 mb-1 btn btn-facebook btn-lg"
        >
          <FontAwesomeIcon icon={faFacebook} className="align-middle" />{" "}
          Facebook
        </LoginButton>
      </FacebookProvider>
    );
  }
}
