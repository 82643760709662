import { Badge, Card, CardBody, CardHeader, CardTitle } from "reactstrap";

import { Line } from "react-chartjs-2";
import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import moment from "moment";

const LineChart = ({
  theme,
  twelveMonthShares,
  twelveMonthViews,
  monthLabels
}) => {
  const data = {
    labels: monthLabels,
    datasets: [
      {
        label: "Number of Shares",
        fill: true,
        backgroundColor: "transparent",
        borderColor: theme.success,
        data: twelveMonthShares
      },
      {
        label: "Shares Viewed",
        fill: true,
        backgroundColor: "transparent",
        borderColor: theme.info,
        borderDash: [4, 4],
        data: twelveMonthViews
      }
    ]
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      intersect: false
    },
    hover: {
      intersect: true
    },
    plugins: {
      filler: {
        propagate: false
      }
    },
    scales: {
      xAxes: [
        {
          reverse: true,
          gridLines: {
            color: "rgba(0,0,0,0.05)"
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            stepSize: 1000
          },
          display: false,
          borderDash: [5, 5],
          gridLines: {
            color: "rgba(0,0,0,0)",
            fontColor: "#fff"
          }
        }
      ]
    }
  };

  return (
    <Card className="flex-fill w-100">
      <CardHeader>
        <Badge color="primary" className="float-right">
          Monthly
        </Badge>
        <CardTitle tag="h5" className="mb-0">
          Last 12 Months
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div className="chart chart-lg">
          <Line data={data} options={options} />
        </div>
      </CardBody>
    </Card>
  );
};

export default connect(store => ({
  theme: store.theme.currentTheme,
  twelveMonthShares: store.dashboard.twelveMonthShares.map(mo => mo.count),
  twelveMonthViews: store.dashboard.twelveMonthViews.map(mo => mo.count),
  monthLabels: [
    moment()
      .subtract(11, "months")
      .format("MMM"),
    moment()
      .subtract(10, "months")
      .format("MMM"),
    moment()
      .subtract(9, "months")
      .format("MMM"),
    moment()
      .subtract(8, "months")
      .format("MMM"),
    moment()
      .subtract(7, "months")
      .format("MMM"),
    moment()
      .subtract(6, "months")
      .format("MMM"),
    moment()
      .subtract(5, "months")
      .format("MMM"),
    moment()
      .subtract(4, "months")
      .format("MMM"),
    moment()
      .subtract(3, "months")
      .format("MMM"),
    moment()
      .subtract(2, "months")
      .format("MMM"),
    moment()
      .subtract(1, "months")
      .format("MMM"),
    moment().format("MMM")
  ]
}))(LineChart);
