import * as types from "../constants";

export function setDirty(isDirty) {
  return {
    type: types.SET_THREADS_DIRTY,
    isDirty: isDirty,
  };
}

export function setThreads(threads) {
  return {
    type: types.SET_THREADS,
    threads: threads,
  };
}
