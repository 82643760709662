import * as types from "../constants";
import { reduce } from "./_genericReducer";

const initialState = {
  isDirty: true,
  charities: [],
  myCharityName: null,
  isParent: false,
};

export default function reducer(state = initialState, actions) {
  if (actions.module === "CHARITIES") {
    const result = reduce(state, actions, initialState);
    if (result) return result;
  }

  switch (actions.type) {
    case types.SET_CHARITIES:
      return {
        ...state,
        charities: actions.charities,
      };

    case types.SET_CHARITIES_DIRTY:
      return {
        ...state,
        isDirty: actions.isDirty,
      };

    default:
      return state;
  }
}
