export function reduce(state, actions, initialState) {
  let newState = { ...state };

  switch (actions.type) {
    case "SET_PROP":
      newState[actions.prop] = actions.value;
      return newState;
    case "TOGGLE_PROP":
      newState[actions.prop] = !newState[actions.prop];
      return newState;
    case "APPEND_ARRAY":
      newState[actions.prop] = [...newState[actions.prop], actions.value];
      return newState;
    case "REMOVE_FROM_ARRAY":
      newState[actions.prop] = newState[actions.prop].filter(
        (e) => e !== actions.value
      );
      return newState;
    case "RESET_PROPS":
      return initialState;
    default:
      return null;
  }
}
