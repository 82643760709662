import { sendRequest, sendRequestAsync } from "../apiService";

export function sendMediaShare(data, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/shares/media`,
      method: "post",
    },
    data,
    success: (response) => {
      if (callback) {
        callback(response);
      }
    },
    failure: () => {
      if (callback) {
        callback();
      }
    },
  });
}

export function sendStandardShare(data, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/shares/standard`,
      method: "post",
    },
    data,
    success: (response) => {
      if (callback) {
        callback(response);
      }
    },
    failure: () => {
      if (callback) {
        callback();
      }
    },
  });
}

export function saveDraft(data, callback, failureCallback) {
  sendRequest({
    request: {
      resource: `/charities/me/shares/drafts`,
      method: "post",
    },
    data,
    success: (response) => {
      if (callback) {
        callback(response);
      }
    },
    failure: () => {
      if (failureCallback) {
        failureCallback();
      }
    },
  });
}

export function getDrafts(callback, failureCallback) {
  sendRequest({
    request: {
      resource: `/charities/me/shares/drafts`,
      method: "get",
    },
    success: (response) => {
      if (callback) {
        callback(response);
      }
    },
    failure: () => {
      if (failureCallback) {
        failureCallback();
      }
    },
  });
}

export function getDraft(id, callback, failureCallback) {
  sendRequest({
    request: {
      resource: `/charities/me/shares/drafts/${id}`,
      method: "get",
    },
    success: (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    failure: () => {
      if (failureCallback) {
        failureCallback();
      }
    },
  });
}

export function deleteDraft(id, callback, failureCallback) {
  sendRequest({
    request: {
      resource: `/charities/me/shares/drafts/${id}`,
      method: "delete",
    },
    success: (response) => {
      if (callback) {
        callback(response);
      }
    },
    failure: () => {
      if (failureCallback) {
        failureCallback();
      }
    },
  });
}

export function editDraft(id, data, callback, failureCallback) {
  sendRequest({
    request: {
      resource: `/charities/me/shares/drafts/${id}`,
      method: "put",
    },
    data,
    success: (response) => {
      if (callback) {
        callback(response);
      }
    },
    failure: () => {
      if (failureCallback) {
        failureCallback();
      }
    },
  });
}

export function getScheduled(callback, failureCallback) {
  sendRequest({
    request: {
      resource: `/charities/me/shares/scheduled`,
      method: "get",
    },
    success: (response) => {
      if (callback) {
        callback(response);
      }
    },
    failure: () => {
      if (failureCallback) {
        failureCallback();
      }
    },
  });
}

export async function getScheduledAsync() {
  try {
    const response = await sendRequestAsync({
      request: {
        resource: `/charities/me/shares/scheduled`,
        method: "get",
      },
      data: null,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
}

export function cancelScheduled(id, callback, failureCallback) {
  sendRequest({
    request: {
      resource: `/charities/me/shares/scheduled/${id}/cancel`,
      method: "put",
    },
    success: (response) => {
      if (callback) {
        callback(response);
      }
    },
    failure: () => {
      if (failureCallback) {
        failureCallback();
      }
    },
  });
}

export function getScheduledDetails(id, callback, failureCallback) {
  sendRequest({
    request: {
      resource: `/charities/me/shares/scheduled/${id}`,
      method: "get",
    },
    success: (response) => {
      if (callback) {
        callback(response.data);
      }
    },
    failure: () => {
      if (failureCallback) {
        failureCallback();
      }
    },
  });
}

export function removeScheduledDonors(donors, id, callback, failureCallback) {
  sendRequest({
    request: {
      resource: `/charities/me/shares/scheduled/${id}/donors/remove`,
      method: "put",
    },
    data: { donors },
    success: (response) => {
      if (callback) {
        callback(response);
      }
    },
    failure: () => {
      if (failureCallback) {
        failureCallback();
      }
    },
  });
}
