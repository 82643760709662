import AppleLogin from "react-apple-login";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faApple } from "@fortawesome/free-brands-svg-icons";

export default function Apple({ redirectURI }) {
  return (
    <AppleLogin
      clientId={process.env.REACT_APP_APPLE_CLIENT_ID}
      redirectURI={redirectURI}
      render={(renderProps) => (
        <Button
          color="dark"
          size="lg"
          className="mr-1 mb-1"
          onClick={renderProps.onClick}
        >
          <FontAwesomeIcon icon={faApple} className="align-middle" /> Apple
        </Button>
      )}
    />
  );
}
