import { Col, Container, Row, Button } from "reactstrap";
import React from "react";

const Footer = () => (
  <footer style={{zIndex: 100}} className="footer">
    <Container fluid>
      <Row className="text-muted">
        <Col xs="4" className="text-left">
          <ul className="list-inline">
            <li className="list-inline-item">
              <span className="text-muted">
                <a href="https://kindkatch.com/privacy-policy/" target="_blank" rel="noopener noreferrer" >
                  Privacy Policy
                </a>
              </span>
            </li>
          </ul>
        </Col>
        <Col xs="6" className="text-right">
          <ul className="list-inline">
            <li className="footer-list list-inline-item">
              <span className="text-muted">
                <a
                  href="https://apps.apple.com/us/app/kindkatch/id1501880092"
                  target="_blank"
                  rel="noopener noreferrer" 
                >
                  <Button type="button" color="dark" className="">
                    Download on iOS
                  </Button>
                </a>
              </span>
            </li>
            <li className="footer-list list-inline-item">
              <span className="text-muted">
                <a
                  href="https://play.google.com/store/apps/details?id=com.empathy313.kindkatch"
                  target="_blank"
                  rel="noopener noreferrer" 
                >
                  <Button type="button" color="dark" className="">
                    Download on Google Play
                  </Button>
                </a>
              </span>
            </li>
            <li className="list-inline-item">
              <p className="mb-0">
                &copy; {new Date().getFullYear()} -{" "}
                <span href="/" className="text-muted">
                  KindKatch
                </span>
              </p>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
