import { reduce } from "./_genericReducer";

const initialState = {
  charityMetrics: null
};

export default function reducer(state = initialState, actions) {
  if (actions.module === "METRICS") {
    const result = reduce(state, actions, initialState);
    if (result) return result;
  }

  let newState = { ...state };

  switch (actions.type) {
    default:
      return state;
  }
}
