import { AUTH_ROUTES } from "../routes/constants.js";
import Page404 from "../pages/shared/Page404";
import Page500 from "../pages/shared/Page500";
import SignIn from "../pages/shared/SignIn";
import SignUp from "../pages/shared/SignUp";
import SignUpForward from "../pages/shared/SignUpForward";
import ResetPassword from "../pages/shared/ResetPassword.js";
import { Users as UsersIcon } from "react-feather";

export const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: UsersIcon,
  badgeColor: "secondary",
  badgeText: "12/24",
  children: [
    {
      path: AUTH_ROUTES.SIGN_IN,
      name: "Sign In",
      component: SignIn,
    },
    {
      path: AUTH_ROUTES.SIGN_UP,
      name: "Sign Up",
      component: SignUp,
    },
    {
      path: AUTH_ROUTES.SIGN_UP_FORWARD,
      name: "Sign Up",
      component: SignUpForward,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: AUTH_ROUTES.ERROR_404,
      name: "404 Page",
      component: Page404,
    },
    {
      path: AUTH_ROUTES.ERROR_500,
      name: "500 Page",
      component: Page500,
    },
  ],
};
