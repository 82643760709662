import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

import { ACCOUNT_ROUTES } from "../../../routes/constants";
import BootstrapTable from "react-bootstrap-table-next";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";
import React from "react";
import { Redirect } from "react-router";
import { getRequestTemplates } from "../../../services/apiActions/templates";

export class MediaRequestTemplates extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      charity: {},
      loading: true,
    };

    const self = this;

    getRequestTemplates((response) => {
      self.setState({
        requestTemplates: response,
        loading: false,
      });
    });
  }

  rowEvents = {
    onClick: (e, row) => {
      this.setState({ toRequestTemplateId: row.id });
    },
  };

  requestTemplateColumns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
  ];

  render() {
    if (this.state.loading) {
      return <Loader />;
    }

    if (this.state.toRequestTemplateId) {
      return (
        <Redirect
          push
          to={ACCOUNT_ROUTES.EDIT_REQUEST_TEMPLATE.replace(
            ":requestTemplateId",
            this.state.toRequestTemplateId
          )}
        />
      );
    }

    return (
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Media Request Templates</h1>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <div className="card-actions float-right">
                  <Link to={ACCOUNT_ROUTES.CREATE_REQUEST_TEMPLATE}>
                    <Button outline>Add New Template</Button>
                  </Link>
                </div>
                <CardTitle tag="h5">Templates</CardTitle>
              </CardHeader>
              <CardBody>
                {this.state.requestTemplates ? (
                  <BootstrapTable
                    bootstrap4
                    bordered={false}
                    keyField="name"
                    data={this.state.requestTemplates}
                    columns={this.requestTemplateColumns}
                    rowEvents={this.rowEvents}
                    hover={true}
                    rowStyle={{ cursor: "pointer" }}
                  />
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default MediaRequestTemplates;
