import { sendRequest } from "../apiService";
import { setDirty } from "../../redux/actions/donationsActions";
import { setProp } from "../../redux/actions/genericActions";
import store from "../../redux/store";

export function getDonors(callback) {
  // if (store.getState().donations.isDirty) {
  sendRequest({
    request: {
      resource: `/charities/me/donors/v2`,
      method: "get",
    },
    data: null,
    success: (response) => {
      store.dispatch(setProp("donations", "donors", response.data));
      store.dispatch(setDirty(false));
      if (callback) callback(response.data);
    },
  });
  // }
}

export function getMyDonors(callback) {
  sendRequest({
    request: {
      resource: `/charities/me/donors/v2`,
      method: "get",
    },
    data: null,
    success: (response) => {
      if (callback) callback(response.data);
    },
  });
}

export function mergeDonors(data, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/donors/merge/`,
      method: "post",
    },
    data: data,
    success: async (response) => {
      await store.dispatch(setDirty(true));
      if (callback) callback(response);
    },
  });
}

export function getRecentDonorTags(callback) {
  sendRequest({
    request: {
      resource: `/charities/me/donors/tags/recent`,
      method: "get",
    },
    data: null,
    success: (response) => {
      if (callback) callback(response.data);
    },
  });
}

export function unsubscribe(donorShareId, callback) {
  sendRequest({
    request: {
      resource: `/charities/donors/unsubscribe/${donorShareId}`,
      method: "post",
    },
    success: (response) => {
      if (callback) callback(response);
    },
  });
}

export function getUnsubscribed(callback) {
  sendRequest({
    request: {
      resource: `/charities/me/donors/reports/unsubscribed`,
      method: "get",
    },
    success: (response) => {
      callback(response.data);
    },
  });
}

export function getSmsStop(callback) {
  sendRequest({
    request: {
      resource: `/charities/me/donors/reports/sms-stop`,
      method: "get",
    },
    success: (response) => {
      callback(response.data);
    },
  });
}

export function getDonorImportDetails(callback) {
  sendRequest({
    request: {
      resource: `/charities/me/donors/import`,
      method: "get",
    },
    data: null,
    success: (response) => {
      if (callback) callback(response.data);
    },
  });
}

export function uploadImportFile(file, successCallback, failureCallback) {
  var data = new FormData();
  data.append("file", file, file.name);

  sendRequest({
    request: {
      resource: `/charities/me/donors/import/upload`,
      method: "post",
    },
    data: data,
    config: { headers: { "Content-Type": "multipart/form-data" } },
    success: async (response) => {
      await store.dispatch(setDirty(true));
      if (successCallback) successCallback(response.data);
    },
    failure: async () => {
      failureCallback && failureCallback();
    },
  });
}

export function createDonor(donor, callback) {
  return new Promise((resolve) => {
    sendRequest({
      request: {
        resource: `/charities/me/donors/`,
        method: "post",
      },
      data: donor,
      success: async (response) => {
        await store.dispatch(setDirty(true));
        if (callback) callback(response);
      },
    });
  });
}

export function getDonor(donorId, callback) {
  if (callback) {
    sendRequest({
      request: {
        resource: `/charities/me/donors/update/${donorId}`,
        method: "get",
      },
      data: null,
      success: async (response) => {
        await store.dispatch(setDirty(true));
        callback(response.data);
      },
    });
  }
}

export function getDonorDetails(donorId, callback) {
  if (callback) {
    sendRequest({
      request: {
        resource: `/charities/me/donors/details/${donorId}`,
        method: "get",
      },
      data: null,
      success: (response) => {
        callback(response.data);
      },
    });
  }
}

export function updateDonor(donorId, donor, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/donors/update/${donorId}`,
      method: "put",
    },
    data: donor,
    success: async (response) => {
      if (callback) callback(response);
    },
  });
}

export function archiveDonor(donorId, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/donors/archive/${donorId}`,
      method: "put",
    },
    success: async (response) => {
      if (callback) callback(response);
    },
  });
}

export function addDonorTags(donorIds, tags, successCallback, failureCallback) {
  sendRequest({
    request: {
      resource: `/charities/me/donors/tags/`,
      method: "put",
    },
    data: { donors: donorIds, tags: tags },
    success: async (response) => {
      if (successCallback) successCallback(response);
    },
  });
}

export function getCharityPublicDonorRequests(callback) {
  sendRequest({
    request: {
      resource: `/charities/me/public-donor-requests`,
      method: "get",
    },
    success: (response) => {
      callback(response.data);
    },
  });
}

export function getCharityPublicDonorRequest(id, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/public-donor-requests/${id}`,
      method: "get",
    },
    success: (response) => {
      callback(response.data);
    },
  });
}

export function createCharityPublicDonorRequest(dto, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/public-donor-requests`,
      method: "post",
    },
    data: dto,
    success: (response) => {
      callback(response);
    },
  });
}

export function updateCharityPublicDonorRequest(id, dto, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/public-donor-requests/${id}`,
      method: "put",
    },
    data: dto,
    success: (response) => {
      callback(response);
    },
  });
}

export function archiveCharityPublicDonorRequest(id, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/public-donor-requests/${id}`,
      method: "delete",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function getPublicDonorRequest(id, callback) {
  sendRequest({
    request: {
      resource: `/public/donor/requests/${id}`,
      method: "get",
    },
    success: (response) => {
      callback(response.data);
    },
  });
}

export async function createPublicDonor(id, dto, callback) {
  sendRequest({
    request: {
      resource: `/public/donor/requests/${id}`,
      method: "post",
    },
    data: dto,
    success: (response) => {
      callback(response);
    },
  });
}
