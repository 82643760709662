import * as types from "../constants";

import { ADMIN_ROLES, PUBLIC_ROLES } from "../../roleConstants";

export function signOut() {
  return {
    type: types.SIGN_OUT,
  };
}

export function setUserData(displayName, userRoles, apiKey) {
  return {
    type: types.SET_USER_DATA,
    displayName: displayName,
    userRoles: userRoles,
    apiKey: apiKey,
  };
}

export function debugBecomeCharityAdmin() {
  return {
    type: types.DEBUG_UPDATE_ROLES,
    roles: [PUBLIC_ROLES.ADMINISTRATOR],
  };
}
export function debugBecomeContentCreator() {
  return {
    type: types.DEBUG_UPDATE_ROLES,
    roles: [PUBLIC_ROLES.CONTENT_CREATOR],
  };
}
export function debugBecomeContentManager() {
  return {
    type: types.DEBUG_UPDATE_ROLES,
    roles: [PUBLIC_ROLES.CONTENT_REVIEWER],
  };
}

export function debugBecomeSystemAdmin() {
  return {
    type: types.DEBUG_UPDATE_ROLES,
    roles: [ADMIN_ROLES.ADMINISTRATOR],
  };
}
