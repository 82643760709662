import { setDirty, setMedia } from "../../redux/actions/mediaActions";

import dayjs from "dayjs";
import { sendRequest } from "../apiService";
import { setProp } from "../../redux/actions/genericActions";
import store from "../../redux/store";

export function getMedia(callback) {
  // if (store.getState().media.isDirty) {
  sendRequest({
    request: {
      resource: `/charities/me/media`,
      method: "get",
    },
    success: (response) => {
      store.dispatch(
        setMedia(
          response.data.map((datum) => {
            return { ...datum, uploadDate: new Date(datum.uploadDate) };
          })
        )
      );
      store.dispatch(setDirty(false));
      if (callback) callback(response);
    },
  });
}
// }
export function getMyMedia(callback) {
  sendRequest({
    request: {
      resource: `/charities/me/media`,
      method: "get",
    },
    data: null,
    success: (response) => {
      let data = response.data.map((datum) => {
        return { ...datum, uploadDate: new Date(datum.uploadDate) };
      });
      callback(data);
    },
  });
}

export function getMyThumbnails(callback) {
  sendRequest({
    request: {
      resource: `/charities/me/media/thumbnails`,
      method: "get",
    },
    data: null,
    success: (response) => {
      let data = response.data.map((datum) => {
        return { ...datum, uploadDate: new Date(datum.uploadDate) };
      });
      callback(data);
    },
  });
}

export function getMedium(mediumId, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/media/${mediumId}`,
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response.data);
    },
  });
}

export function createMediumProperties(medium, file) {
  const mediumType = file.type.split("/")[0];

  return new Promise((resolve) => {
    sendRequest({
      request: {
        resource: `/charities/me/media/${mediumType}s`,
        method: "post",
      },
      data: medium,
      success: (response) => {
        store.dispatch(setDirty(true));
        const splitMediaUrl = response.headers.location.split("/");
        const mediaId = splitMediaUrl[splitMediaUrl.length - 1];

        resolve(mediaId);
      },
    });
  });
}

export function createThumbnailProperties(medium, file) {
  return new Promise((resolve) => {
    sendRequest({
      request: {
        resource: `/charities/me/media/thumbnails`,
        method: "post",
      },
      data: medium,
      success: (response) => {
        store.dispatch(setDirty(true));
        const splitMediaUrl = response.headers.location.split("/");
        const mediaId = splitMediaUrl[splitMediaUrl.length - 1];

        resolve(mediaId);
      },
    });
  });
}

export function publicCreateMediumProperties(
  mediaRequestId,
  donorId,
  notes,
  file
) {
  const mediumType = file.type.split("/")[0];

  return new Promise((resolve) => {
    sendRequest({
      request: {
        resource: `/media/requests/${mediaRequestId}/donors/${donorId}/${mediumType}s`,
        method: "post",
      },
      data: notes,
      success: (response) => {
        store.dispatch(setDirty(true));
        const mediaId = response.data;
        resolve(mediaId);
      },
    });
  });
}

export async function publicAddMedium(
  mediaId,
  mediaRequestId,
  donorId,
  file,
  progressCallback
) {
  const mediaType = file.type.split("/")[0];

  return new Promise((resolve) => {
    var data = new FormData();
    data.append("file", file, file.name);

    sendRequest({
      request: {
        resource: `/media/requests/${mediaType}s/${mediaId}/${mediaRequestId}/${donorId}`,
        method: "post",
      },
      data: data,
      config: { headers: { "Content-Type": "multipart/form-data" } },
      progressCallback,
      success: (response) => {
        resolve();
      },
    });
  });
}

export async function addMedium(mediaId, file, progressCallback) {
  const mediaType = file.type.split("/")[0];

  return new Promise((resolve) => {
    var data = new FormData();
    data.append("file", file, file.name);

    sendRequest({
      request: {
        resource: `/charities/me/media/${mediaType}s/${mediaId}`,
        method: "post",
      },
      data: data,
      config: { headers: { "Content-Type": "multipart/form-data" } },
      progressCallback,
      success: (response) => {
        resolve();
      },
    });
  });
}

export async function addThumbnail(mediaId, file, progressCallback) {
  return new Promise((resolve) => {
    var data = new FormData();
    data.append("file", file, file.name);

    sendRequest({
      request: {
        resource: `/charities/me/media/thumbnails/${mediaId}`,
        method: "post",
      },
      data: data,
      config: { headers: { "Content-Type": "multipart/form-data" } },
      progressCallback,
      success: (response) => {
        resolve();
      },
    });
  });
}

export function updateMedium(mediumId, medium, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/media/${mediumId}`,
      method: "put",
    },
    data: medium,
    success: (response) => {
      //store.dispatch(setDirty(true));
      if (callback) callback(response);
    },
  });
}

export function getPopularTags(callback) {
  sendRequest({
    request: {
      resource: `/charities/me/media/tags/popular`,
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response.data);
    },
  });
}

export function getRecentTags(callback) {
  sendRequest({
    request: {
      resource: `/charities/me/media/tags/recent`,
      method: "get",
    },
    data: null,
    success: (response) => {
      callback(response.data);
    },
  });
}

export function shareMedia(mediumId, options, donors, method, callback) {
  const data = { ...options, donors, method };
  sendRequest({
    request: {
      resource: `/charities/me/media/${mediumId}/share`,
      method: "post",
    },
    data: data,
    success: (response) => {
      store.dispatch(
        setProp(
          "DASHBOARD",
          "sharesSent",
          store.getState().dashboard.sharesSent + donors.length
        )
      );
      callback(response);
    },
  });
}

export function sendResponse(shareId, charityId, message, callback) {
  const data = { message };
  sendRequest({
    request: {
      resource: `/messages/shares/donors/${shareId}/charities/${charityId}`,
      method: "post",
    },
    data: data,
    success: (response) => {
      callback(response);
    },
  });
}

export function getResponses(callback) {
  sendRequest({
    request: {
      resource: `/charities/me/messages`,
      method: "get",
    },
    success: (response) => {
      callback(response.data);
    },
  });
}

export function requestMedia(options, donors, method, callback) {
  const data = {
    ...options,
    method,
    expirationDate: dayjs().add(1, "day"),
    donors,
  };
  sendRequest({
    request: {
      resource: `/charities/me/media/requests`,
      method: "post",
    },
    data: data,
    success: (response) => {
      store.dispatch(
        setProp(
          "DASHBOARD",
          "sharesSent",
          store.getState().dashboard.sharesSent + donors.length
        )
      );
      callback(response);
    },
  });
}

export function getMediaRequest(shareId, donorId, callback) {
  sendRequest({
    request: {
      resource: `/media/requests/${shareId}/${donorId}`,
      method: "get",
    },
    success: (response) => {
      callback(response.data);
    },
  });
}

export function getShare(shareId, callback) {
  sendRequest({
    request: {
      resource: `/media/${shareId}`,
      method: "get",
    },
    success: (response) => {
      callback(response.data);
    },
  });
}

export function getCharityPublicMediaRequests(callback) {
  sendRequest({
    request: {
      resource: `/charities/me/public-media-requests`,
      method: "get",
    },
    success: (response) => {
      callback(response.data);
    },
  });
}

export function getCharityPublicMediaRequest(id, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/public-media-requests/${id}`,
      method: "get",
    },
    success: (response) => {
      callback(response.data);
    },
  });
}

export function createCharityPublicMediaRequest(dto, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/public-media-requests`,
      method: "post",
    },
    data: dto,
    success: (response) => {
      callback(response);
    },
  });
}

export function updateCharityPublicMediaRequest(id, dto, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/public-media-requests/${id}`,
      method: "put",
    },
    data: dto,
    success: (response) => {
      callback(response);
    },
  });
}

export function archiveCharityPublicMediaRequest(id, callback) {
  sendRequest({
    request: {
      resource: `/charities/me/public-media-requests/${id}`,
      method: "delete",
    },
    data: null,
    success: (response) => {
      callback(response);
    },
  });
}

export function getPublicMediaRequest(id, callback) {
  sendRequest({
    request: {
      resource: `/public/media/requests/${id}`,
      method: "get",
    },
    success: (response) => {
      callback(response.data);
    },
  });
}

export function createPublicMediaMetadata(id, dto, file) {
  const mediaType = file.type.split("/")[0];

  return new Promise((resolve) => {
    sendRequest({
      request: {
        resource: `/public/media/requests/${id}/${mediaType}s`,
        method: "post",
      },
      data: dto,
      success: (response) => {
        store.dispatch(setDirty(true));
        const mediaId = response.data;
        resolve(mediaId);
      },
    });
  });
}

export async function createPublicMedia(id, mediaId, file, progressCallback) {
  const mediaType = file.type.split("/")[0];

  return new Promise((resolve) => {
    var data = new FormData();
    data.append("file", file, file.name);

    sendRequest({
      request: {
        resource: `/public/media/requests/${id}/${mediaType}s/${mediaId}`,
        method: "post",
      },
      data: data,
      config: { headers: { "Content-Type": "multipart/form-data" } },
      progressCallback,
      success: (response) => {
        resolve();
      },
    });
  });
}
