import admins from "./adminsReducer";
import charities from "./charitiesReducer";
import { combineReducers } from "redux";
import dashboard from "./dashboardReducer";
import donations from "./donationsReducer";
import layout from "./layoutReducer";
import media from "./mediaReducer";
import share from "./shareReducer";
import sidebar from "./sidebarReducers";
import theme from "./themeReducer";
import metrics from "./metricsReducer";
import threads from "./threadReducer";
import { reducer as toastr } from "react-redux-toastr";
import user from "./userReducer";

export default combineReducers({
  admins,
  charities,
  dashboard,
  donations,
  media,
  user,
  sidebar,
  metrics,
  threads,
  layout,
  share,
  theme,
  toastr,
});
