import { sendRequest } from "../apiService";

export function logEngagement(data, onSuccess) {
  sendRequest({
    request: {
      resource: "/engagement",
      method: "post",
    },
    data: data,
    success: (response) => {
      if (onSuccess) onSuccess(response);
    },
  });
}
