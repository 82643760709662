import { toastr } from "react-redux-toastr";

export const pushAlert = function (alert) {
  const options = {
    timeOut: alert.timeOut ? alert.timeOut : 3000,
    showCloseButton: true,
    progressBar: true,
    position: "top-right",
  };

  const toastrInstance =
    alert.type === "info"
      ? toastr.info
      : alert.type === "warning"
      ? toastr.warning
      : alert.type === "error"
      ? toastr.error
      : toastr.success;

  toastrInstance(alert.message, options);
};
