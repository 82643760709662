import * as types from "../constants";

export function setDirty(isDirty) {
  return {
    type: types.SET_DONATIONS_DIRTY,
    isDirty: isDirty,
  };
}
export function setDonations(donations) {
  return {
    type: types.SET_DONATIONS,
    donations: donations,
  };
}
export function setNameFilter(name) {
  return {
    type: types.SET_DONATIONS_NAME_FILTER,
    nameFilter: name,
  };
}
export function setStartDateFilter(date) {
  return {
    type: types.SET_DONATIONS_START_DATE_FILTER,
    startDate: date,
  };
}
export function setEndDateFilter(date) {
  return {
    type: types.SET_DONATIONS_END_DATE_FILTER,
    endDate: date,
  };
}
export function setChildOrganizationFilters(childOrgs) {
  return {
    type: types.SET_CHILDORGANIZATION_FILTERS,
    childOrgs: childOrgs,
  };
}
